import { createAction } from '@ngrx/store'
import { BuiTab } from '@fleet-customer/booster-ui'
import { AccountIds } from '../../_shared/typings'

export const servicesIndexActions = {
  INIT: createAction(
    '[Services Index Page] Initialize',
    (
      fleetAccountIds: AccountIds,
      subAccountIds: AccountIds,
      selectedFleetCompanyId: string,
      selectedFleetAccountId: string
    ) => ({
      payload: {
        fleetAccountIds,
        subAccountIds,
        selectedFleetCompanyId,
        selectedFleetAccountId,
      },
    })
  ),

  RESET_STATE: createAction('[Services Index Page] Reset State'),

  SELECT_FLEET_ACCOUNTS: createAction(
    '[Service Index Page] Select Fleet Accounts',
    (fleetAccountIds: AccountIds) => ({
      payload: { fleetAccountIds },
    })
  ),

  SELECT_SUBACCOUNTS: createAction(
    '[Service Index Page] Select SubAccounts',
    (subAccountIds: AccountIds) => ({
      payload: { subAccountIds },
    })
  ),

  SAVE_ACTIVE_TAB_VALUE: createAction(
    '[Service Index Page] Save Active Tab',
    (activeTabValue: BuiTab['value']) => ({
      payload: { activeTabValue },
    })
  ),
}
